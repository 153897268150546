import React from "react"
import Layout from "../components/Layout/Layout"
import { Category } from "../types/category"
import SEO from "../components/SEO/SEO"
import productsJSON from "../data/products.json"
import ProductHero from "../components/ProductHero/ProductHero"
import CategoryProducts from "../components/CategoryProducts/CategoryProducts"

const CategoryTemplate: React.FC<{ pageContext: { category: Category } }> = ({
  pageContext: { category },
}) => {
  const products = productsJSON.products
  const mainProduct = products.find(
    ({ category_main, categories }) =>
      category_main && categories.includes(category.id)
  )
  return (
    <>
      <SEO title={category.name} />
      <Layout>
        {mainProduct ? <ProductHero product={mainProduct} /> : null}
        <div>
          <CategoryProducts
            categoryId={category.id}
            exclude={mainProduct ? [mainProduct.id] : undefined}
          />
        </div>
      </Layout>
    </>
  )
}

export default CategoryTemplate
