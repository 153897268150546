import { Product } from "../../types/product"
import React from "react"
import styles from "./ProductHero.module.css"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Badge from "../Badge/Badge"
import { price } from "../../utils"

const ProductHero: React.FC<{ product: Product }> = ({ product }) => {
  const {
    desktopAllFile: { edges: desktopHeroImages },
    mobileAllFile: { edges: mobileHeroImages },
  } = useStaticQuery(graphql`
    {
      desktopAllFile: allFile(
        filter: {
          name: { eq: "desktop-hero" }
          relativeDirectory: { regex: "/products/*/" }
        }
      ) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      mobileAllFile: allFile(
        filter: {
          name: { eq: "mobile-hero" }
          relativeDirectory: { regex: "/products/*/" }
        }
      ) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const {
    node: {
      childImageSharp: { fluid: desktopHeroImage },
    },
  } = desktopHeroImages.find(({ node: { relativePath } }) =>
    relativePath.includes(`/${product.id}/`)
  )
  const {
    node: {
      childImageSharp: { fluid: mobileHeroImage },
    },
  } = mobileHeroImages.find(({ node: { relativePath } }) =>
    relativePath.includes(`/${product.id}/`)
  )

  return (
    <div
      onClick={() => navigate(`/${product.slug}`)}
      className={styles.productHero}
    >
      <Image fluid={desktopHeroImage} className={styles.imageDesktop} />
      <Image fluid={mobileHeroImage} className={styles.imageMobile} />
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.name}>{product.name}</div>
          <div className={styles.badges}>
            <Badge color="primary" className={styles.badge}>
              <b>{price(product.price)}</b>
            </Badge>
            <div className={styles.badgesSeparator}></div>
            {product.compatible.map((item, idx) => (
              <Badge key={idx} icon={item} className={styles.badge}>
                {item}
              </Badge>
            ))}
          </div>
          <div className={styles.description}>{product.description}</div>
        </div>
      </div>
    </div>
  )
}

export default ProductHero
